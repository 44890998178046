var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionCard',{attrs:{"Question":_vm.Question,"is_selected":_vm.is_selected,"allows_crud":_vm.allows_crud,"answer_score":_vm.answer_score,"is_running":_vm.is_running,"evaluation_id":_vm.evaluation_id,"empty_answer":_vm.redaction_answer.answer == '' &&
    _vm.user.is_student &&
    _vm.save_attempt > 0 &&
    !_vm.is_finished
      ? true
      : false},on:{"selected":_vm.slotSelectedQuestion,"created":_vm.slotCreatedQuestion,"questionCopied":_vm.slotQuestionCopied,"deletedQuestion":_vm.slotDeletedQuestion,"questionPatched":_vm.slotQuestionPatched,"questionScoreUpdated":_vm.slotQuestionScoreUpdated}},[_vm._t("default",function(){return [_c('div',{staticClass:"content-div"},[(_vm.is_running && _vm.can_answer && !_vm.is_finished)?[_c('StyledTextArea',{staticClass:"answer-text-area",attrs:{"placeholder":"Escriba su respuesta aquí...","max_answer_length_type":_vm.Question.max_answer_length_type,"limited_words_amount":_vm.Question.max_answer_length,"state":true},on:{"input":_vm.debouncePatch,"maxWordsReached":_vm.maxWordsReached,"answerWordsChanged":_vm.answerWordsChanged},model:{value:(_vm.redaction_answer.answer),callback:function ($$v) {_vm.$set(_vm.redaction_answer, "answer", $$v)},expression:"redaction_answer.answer"}}),(
            _vm.redaction_answer.answer.length == _vm.Question.max_answer_length &&
            _vm.Question.max_answer_length_type == 2
          )?_c('div',{staticClass:"my-1",staticStyle:{"color":"#dc3545 !important"}},[_c('b-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" Ya ha alcanzado el límite de "+_vm._s(_vm.Question.max_answer_length)+" caracteres en esta pregunta. ")],1):(
            _vm.max_words_reached && _vm.Question.max_answer_length_type == 1
          )?_c('div',{staticClass:"my-1",staticStyle:{"color":"#dc3545 !important"}},[_c('b-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" Ya ha alcanzado el límite de "+_vm._s(_vm.Question.max_answer_length)+" palabras en esta pregunta. ")],1):_vm._e()]:(
          (!_vm.is_running && _vm.user.is_student) || (_vm.is_finished && _vm.user.is_student)
        )?[_c('div',{staticClass:"text-left mt-1"},[_c('p',[_vm._v("Tu respuesta:")]),_c('p',{staticClass:"answer p-1"},[_vm._v(_vm._s(_vm.redaction_answer.answer))])]),(
            _vm.questionTestScore &&
            _vm.questionTestScore.score_and_feedback &&
            _vm.questionTestScore.score_and_feedback.publish &&
            _vm.redaction_answer &&
            !_vm.is_running &&
            _vm.redaction_answer.score_and_feedback
          )?_c('div',{staticClass:"text-left mt-1"},[(_vm.redaction_answer.score_and_feedback.feedback.trim() != '')?[_c('strong',[_vm._v("Retroalimentación:")]),_c('p',{staticClass:"answer p-1"},[_vm._v(" "+_vm._s(_vm.redaction_answer.score_and_feedback.feedback)+" ")])]:_vm._e(),(_vm.Question && _vm.Question.comment.trim() != '')?[_c('strong',[_vm._v("Pauta:")]),_c('p',{staticClass:"answer p-1"},[_vm._v(" "+_vm._s(_vm.Question.comment)+" ")])]:_vm._e()],2):_vm._e()]:(_vm.user.is_professor)?[_c('div',{staticClass:"text-left py-4"},[_c('span',{staticClass:"noprint"},[_vm._v("Texto de respuesta aquí...")])])]:_vm._e()],2)]}),_c('div',{staticClass:"save-btn-container"},[(_vm.saved && !_vm.is_finished)?_c('span',{staticClass:"saved-answer-alert text-success mr-2 pt-1"},[_c('b-icon',{attrs:{"icon":"check-circle"}}),_vm._v(" Se ha guardado su respuesta. ")],1):_vm._e(),(_vm.is_running && _vm.user.is_student && !_vm.is_finished)?_c('b-button',{staticClass:"save-answer-btn",class:{ 'saving-answer-btn': _vm.is_saving > 0, 'ml-0': _vm.saved },attrs:{"disabled":!_vm.is_running || _vm.is_saving > 0},on:{"click":function($event){return _vm.forcePatch()}}},[_c('div',{staticClass:"btn-save-container"},[_vm._v(" "+_vm._s(_vm.is_saving > 0 ? "Guardando" : "Guardar Respuesta")+" "),(_vm.is_saving > 0)?_c('div',{staticClass:"dot-flashing"}):_vm._e()])]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <QuestionCard
    :Question="Question"
    :is_selected="is_selected"
    :allows_crud="allows_crud"
    :answer_score="answer_score"
    :is_running="is_running"
    :evaluation_id="evaluation_id"
    @selected="slotSelectedQuestion"
    @created="slotCreatedQuestion"
    @questionCopied="slotQuestionCopied"
    @deletedQuestion="slotDeletedQuestion"
    @questionPatched="slotQuestionPatched"
    @questionScoreUpdated="slotQuestionScoreUpdated"
    :empty_answer="
      redaction_answer.answer == '' &&
      user.is_student &&
      save_attempt > 0 &&
      !is_finished
        ? true
        : false
    "
  >
    <slot>
      <div class="content-div">
        <template v-if="is_running && can_answer && !is_finished">
          <StyledTextArea
            class="answer-text-area"
            v-model="redaction_answer.answer"
            placeholder="Escriba su respuesta aquí..."
            @input="debouncePatch"
            :max_answer_length_type="Question.max_answer_length_type"
            :limited_words_amount="Question.max_answer_length"
            :state="true"
            @maxWordsReached="maxWordsReached"
            @answerWordsChanged="answerWordsChanged"
          ></StyledTextArea>
          <div
            style="color: #dc3545 !important"
            class="my-1"
            v-if="
              redaction_answer.answer.length == Question.max_answer_length &&
              Question.max_answer_length_type == 2
            "
          >
            <b-icon icon="exclamation-circle"></b-icon> Ya ha alcanzado el
            límite de {{ Question.max_answer_length }} caracteres en esta
            pregunta.
          </div>
          <div
            style="color: #dc3545 !important"
            class="my-1"
            v-else-if="
              max_words_reached && Question.max_answer_length_type == 1
            "
          >
            <b-icon icon="exclamation-circle"></b-icon> Ya ha alcanzado el
            límite de {{ Question.max_answer_length }} palabras en esta
            pregunta.
          </div>
        </template>
        <template
          v-else-if="
            (!is_running && user.is_student) || (is_finished && user.is_student)
          "
        >
          <div class="text-left mt-1">
            <p>Tu respuesta:</p>
            <p class="answer p-1">{{ redaction_answer.answer }}</p>
          </div>

          <div
            class="text-left mt-1"
            v-if="
              questionTestScore &&
              questionTestScore.score_and_feedback &&
              questionTestScore.score_and_feedback.publish &&
              redaction_answer &&
              !is_running &&
              redaction_answer.score_and_feedback
            "
          >
            <template
              v-if="redaction_answer.score_and_feedback.feedback.trim() != ''"
            >
              <strong>Retroalimentación:</strong>
              <p class="answer p-1">
                {{ redaction_answer.score_and_feedback.feedback }}
              </p>
            </template>
            <template v-if="Question && Question.comment.trim() != ''">
              <strong>Pauta:</strong>
              <p class="answer p-1">
                {{ Question.comment }}
              </p>
            </template>
          </div>
        </template>
        <template v-else-if="user.is_professor"
          ><div class="text-left py-4">
            <span class="noprint">Texto de respuesta aquí...</span>
          </div></template
        >
        <!-- <StyledTextArea
          disabled
          placeholder="Texto de respuesta corta"
          :state="true"
        ></StyledTextArea> -->
      </div>
    </slot>
    <div class="save-btn-container">
      <span
        v-if="saved && !is_finished"
        class="saved-answer-alert text-success mr-2 pt-1"
      >
        <b-icon icon="check-circle"></b-icon> Se ha guardado su respuesta.
      </span>
      <b-button
        class="save-answer-btn"
        :class="{ 'saving-answer-btn': is_saving > 0, 'ml-0': saved }"
        @click="forcePatch()"
        v-if="is_running && user.is_student && !is_finished"
        :disabled="!is_running || is_saving > 0"
      >
        <div class="btn-save-container">
          {{ is_saving > 0 ? "Guardando" : "Guardar Respuesta" }}
          <div v-if="is_saving > 0" class="dot-flashing"></div>
        </div>
        <!-- Guardar Respuesta -->
      </b-button>
    </div>
  </QuestionCard>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "RedactionQuestion",
  components: {
    QuestionCard: () => import("./QuestionCard"),
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
    // RedactionAnswer: () => import("./RedactionAnswer"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    questionTestScore: {
      type: Object,
      default: null,
    },
    is_selected: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    is_running: {
      type: Boolean,
      default: false,
    },
    can_answer: {
      type: Boolean,
      default: false,
    },
    is_finished: {
      type: Boolean,
      default: false,
    },
    evaluation_id: {
      type: Number,
    },
    save_attempt: {
      type: Number,
      default: 0,
    },
    instrumentScore: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      answer: "",
      redaction_answer: {
        id: generateUniqueId(),
        question: this.Question.id,
        student: this.$store.getters.getUser.id,
        answer: "",
      },
      max_words_reached: false,
      saving: false,
      answer_score: null,
      is_saving: 0,
      saved: false,
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: names.QUESTION_TYPES,
      evaluations: names.EVALUATIONS,
      user: "getUser",
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.Question.evaluation);
    },
  },
  methods: {
    slotCreatedQuestion() {
      this.$emit("created");
    },
    slotQuestionCopied(question) {
      this.$emit("questionCopied", question);
    },
    slotDeletedQuestion(question) {
      this.$emit("deletedQuestion", question);
    },
    slotSelectedQuestion(question_id) {
      this.$emit("selected", question_id);
    },
    debouncePatch() {
      if (this.saving) return;
      this.saving = true;
      this.is_saving += 1;
      this.saved = false;
      this.$emit("isSaving", this.is_saving);
      setTimeout(() => {
        this.save();
        this.is_saving -= 1;
        this.$emit("isSaving", this.is_saving);
      }, 3000);
    },
    forcePatch() {
      if (this.saving) return;
      this.saving = true;
      this.is_saving += 1;
      this.saved = false;
      this.$emit("isSaving", this.is_saving);
      setTimeout(() => {
        if (this.is_saving > 0) {
          setTimeout(() => {
            this.save();
            this.is_saving -= 1;
            this.$emit("isSaving", this.is_saving);
          }, 500);
        }
      }, 1000);
    },
    save() {
      if (isNaN(this.redaction_answer.id)) {
        this.create();
      } else {
        this.patch();
      }
    },
    create() {
      this.$store
        .dispatch(names.POST_REDACTION_ANSWER, this.redaction_answer)
        .then((response) => {
          this.redaction_answer = response;
          this.saving = false;
          this.$emit("createRedactionAnswer");
          toast("Se ha enviado su respuesta.");
          setTimeout(() => {
            this.saved = true;
          }, 100);
        });
    },
    patch() {
      const payload = {
        redaction_answer_id: this.redaction_answer.id,
        item: {
          answer: this.redaction_answer.answer,
        },
      };
      this.$store
        .dispatch(names.PATCH_REDACTION_ANSWER, payload)
        .then((response) => {
          this.redaction_answer = response;
          this.saving = false;
          this.$emit("updateRedactionAnswer", this.redaction_answer);
          toast("Se ha actualizado su respuesta.");
          setTimeout(() => {
            this.saved = true;
          }, 100);
        });
    },
    maxWordsReached() {
      this.max_words_reached = true;
    },
    answerWordsChanged() {
      this.max_words_reached = false;
    },
    slotQuestionPatched(item) {
      setTimeout(() => {
        this.$emit("questionPatched", item.score);
      }, 500);
    },
    redactionQuestionScore() {
      this.$emit("redactionQuestionScore", this.Question.score);
    },
    slotQuestionScoreUpdated(question_score) {
      this.$emit("questionScoreUpdated", question_score);
    },
  },
  mounted() {
    this.redactionQuestionScore();
    if (this.user.is_student) {
      let payload = {
        question_id: this.Question.id,
        user_id: this.user.id,
      };
      this.$store
        .dispatch(names.FETCH_REDACTION_ANSWERS, payload)
        .then((response) => {
          if (response) {
            const answer = response.find((x) => x.student == this.user.id);
            if (answer) {
              this.redaction_answer = answer;
              if (
                !this.is_running &&
                this.user.is_student &&
                this.is_finished &&
                this.instrumentScore &&
                this.instrumentScore.score_and_feedback &&
                this.instrumentScore.score_and_feedback.publish
              )
                this.answer_score = answer.score_and_feedback.score;
              this.$emit("createRedactionAnswer");
            }
          }
        });
    }
  },
  watch: {
    save_attempt() {
      if (this.save_attempt > 0) {
        let payload = {
          question_id: this.redaction_answer.question,
          answer: this.redaction_answer.answer,
        };
        this.$emit("emptyAnswer", payload);
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.content-div {
  margin: 0px 0.5rem;
}
.answer-text-area {
  width: 98%;
}
.empty-answer {
  background: red !important;
}
.answer {
  background: rgb(242, 242, 242);
}
.save-answer-btn {
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  transform: translateY(125%);
}
.saving-answer-btn {
  width: 8.4em;
}
.btn-save-container {
  display: flex;
  margin: auto;
}
.dot-flashing {
  position: relative;
  margin-top: 0.65em;
  left: 11px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -9px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 9px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.save-btn-container {
  display: flex;
}

.saved-answer-alert {
  margin-left: auto;
  margin-right: 0;
  transform: translateY(125%);
}

@keyframes dotFlashing {
  0% {
    background-color: #6c757d;
  }
  50%,
  100% {
    background-color: #ffffff;
  }
}
</style>